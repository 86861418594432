const Config = require('~config/default.env').default;
import axios from 'axios';

const affiliateSendEmail = (payload) => {
  return axios.post(
    `${Config.MS_SOCIOLLA_PUBLIC_API_URL}/affiliates/send-email`,
    payload,
  );
};

const fbConversion = (payload) => {
  return axios.post(
    `${Config.MS_SOCIOLLA_PUBLIC_API_URL}/fb-conversion`,
    payload,
  );
};

export const Sociolla = {
  affiliateSendEmail,
  fbConversion,
};
