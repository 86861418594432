const Config = require('~config/default.env').default;
import axios from 'axios';

const userShippingsRequest = async (params) => {
  return await axios.get(`${Config.MS_SHIPPING_API_URL}/user/me/shippings`, {
    params: { ...params },
  });
};

const getShippingMethods = (params) =>
  axios.get(`${Config.MS_SHIPPING_API_URL}/user/me/shippings`, { params });
const postUserMeShippings = (payload) =>
  axios.post(`${Config.MS_SHIPPING_API_URL}/user/me/shippings`, payload);

export default {
  userShippingsRequest,
  getShippingMethods,
  postUserMeShippings,
};
