const Config = require('~config/default.env').default;
import axios from 'axios';
import Toast from 'react-native-toast-message';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';

const getImageS3Request = (payload) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/pre-signed-url`, payload);
};

const getBlob = (uri, payload) => {
  return axios.get(`${uri}`, payload);
};

const base64ToFile = (base64, filename) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const putImageS3Request = async (url, file, type, filename = Math.random()) => {
  try {
    if (Platform.OS == 'web') {
      const newFile = base64ToFile(file, filename);
      const newAxios = axios;
      delete newAxios.defaults.withCredentials;
      const putResult = await newAxios.put(url, newFile, {
        headers: { 'Content-Type': type },
      });
      axios.defaults.withCredentials = true;
      return putResult;
    }
    return FileSystem.uploadAsync(url, file, {
      httpMethod: 'PUT',
      mimeType: type,
      headers: {
        'Content-Type': type,
      },
    });
  } catch (e) {
    Toast.show({
      type: 'error',
      text1: 'Please try again later.',
      text2: e.message ? e.message : '',
    });
    console.log(e);
  }
};

export const Uploader = {
  getImageS3Request,
  getBlob,
  putImageS3Request,
};
