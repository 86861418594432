const Config = require('~config/default.env').default;
import axios from 'axios';

export function fetchPaymentMethods() {
  return axios.get(`${Config.MS_PAYMENT_API_URL}/payment-methods`, {
    params: {
      filter: { available_for: { $in: 'website' } },
      limit: 20,
    },
  });
}

export function fetchPaymentMethodByCode(payload) {
  return axios.get(`${Config.MS_PAYMENT_API_URL}/payment-methods`, {
    params: {
      filter: { code: payload },
      limit: 1,
    },
  });
}

export function definePaymentMethod({ paymentCode, payload }) {
  return axios.post(
    `${Config.MS_PAYMENT_API_URL}/payments/${paymentCode}`,
    payload,
  );
}

export function callback(payload) {
  return axios.post(`${Config.MS_PAYMENT_API_URL}/payments/callback`, payload);
}

export function fetchListCreditDebitCard() {
  return axios.get(`${Config.MS_PAYMENT_API_URL}/midtrans-tokens`);
}

export function addNewCreditDebitCard(payload) {
  return axios.post(`${Config.MS_PAYMENT_API_URL}/midtrans-token`, payload);
}

export function fetchMidtransToken(payload) {
  return axios.get(`${Config.midtrans.url}/v2/token`, {
    params: {
      client_key: Config.midtrans.client_key,
      bank: 'bca',
      ...payload,
    },
  });
}

export function deleteCreditDebitCardSaved({ _id }) {
  return axios.delete(`${Config.MS_PAYMENT_API_URL}/midtrans-token/${_id}`);
}
