import Colors from '~global/Colors';
import { isWeb } from '~global/screens';

const modalHeader = {
  paddingHorizontal: 20,
  paddingTop: 24,
  paddingBottom: 16,
};

const modalHeading = {
  fontSize: 16,
  fontFamily: 'satoshi-bold',
  lineHeight: 19,
  color: Colors.purple7,
  fontWeight: 'normal',
};

const modalText = {
  color: Colors.black0,
  fontSize: isWeb ? 14 : 13,
  fontFamily: 'satoshi-medium',
  lineHeight: 22,
};

const modalBody = {
  padding: 20,
};

const toastStyle = {
  zIndex: 10,
};

export { modalBody, modalHeader, modalHeading, modalText, toastStyle };
