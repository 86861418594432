/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Dimensions } from 'react-native';
import { BaseToast } from 'react-native-toast-message';
import { isWeb, isNative, isMobileWeb } from '~global/screens';
import Colors from '~global/Colors';

const { width } = Dimensions.get('window');
const toastWidth = width - 30;

const text1Style = {
  fontSize: 13,
  fontFamily: 'satoshi-black',
  color: Colors.white,
  fontWeight: '400',
  ...(isWeb ? { paddingRight: 30 } : { width: toastWidth - 75 }),
};
const iconSuccessStyle = ({ isVisible }) =>
  isVisible ? { width: 27, height: 27 } : { width: 0, height: 0 };

const iconSuccess = require('~assets/img/common/toast-checked.png');
const toastStyle = {
  height: 45,
  borderLeftWidth: 0,
  borderRadius: 100,
  width: isWeb ? 'auto' : toastWidth,
};
const iconResolver = (uri) => (isNative ? uri : { uri });
const getToastHeight = ({ isVisible }) => {
  return !isMobileWeb || isVisible ? 54 : 0;
};

export const toastConfig = {
  success: ({ text1, text2, ...rest }) => {
    return (
      <BaseToast
        {...rest}
        style={{
          ...toastStyle,
          height: getToastHeight(rest),
          backgroundColor: Colors.purple4,
          display: rest.isVisible ? 'flex' : 'none',
        }}
        text1Style={text1Style}
        text2Style={text1Style}
        leadingIcon={iconResolver(iconSuccess)}
        leadingIconStyle={iconSuccessStyle(rest)}
        trailingIcon={null}
        trailingIconStyle={{ display: 'none' }}
        text1={text1}
        text2={text2}
      />
    );
  },
  update: ({ text1, ...rest }) => (
    <BaseToast
      {...rest}
      style={{
        height: getToastHeight(rest),
        borderLeftWidth: 0,
        borderRadius: 100,
        backgroundColor: '#000000',
      }}
      text1Style={text1Style}
      leadingIcon={iconResolver(
        require('~assets/img/common/icon-warning-white.png'),
      )}
      trailingIcon={null}
      trailingIconStyle={{ display: 'none' }}
      text1={text1}
      text2={null}
    />
  ),
  error: ({ text1, text2, ...rest }) => (
    <BaseToast
      {...rest}
      style={{
        ...toastStyle,
        height: getToastHeight(rest),
        backgroundColor: Colors.red0,
        display: rest.isVisible ? 'flex' : 'none',
      }}
      text1Style={text1Style}
      leadingIcon={iconResolver(
        require('~assets/img/common/icon-warning-white.png'),
      )}
      trailingIcon={null}
      trailingIconStyle={{ display: 'none' }}
      text1={text1}
      text1NumberOfLines={2}
      text2={null}
    />
  ),
  feedbackSuccess: ({ text1, ...rest }) => (
    <BaseToast
      {...rest}
      style={{
        height: getToastHeight(rest),
        borderLeftWidth: 0,
        borderRadius: 100,
        backgroundColor: Colors.purple2,
      }}
      text1Style={[text1Style, { color: Colors.black }]}
      leadingIcon={iconResolver(
        require('~assets/img/common/checkbox-checked.png'),
      )}
      leadingIconStyle={{ width: 23, height: 23, borderRadius: 100 }}
      trailingIcon={null}
      trailingIconStyle={{ display: 'none' }}
      text1={text1}
      text2={null}
    />
  ),
  custom: ({ text1, ...rest }) => {
    return (
      <BaseToast
        {...rest}
        style={{
          ...toastStyle,
          height: getToastHeight(rest),
          ...rest?.props?.style,
        }}
        text1Style={{ ...text1Style, ...rest?.props?.text1Style }}
        leadingIcon={
          rest?.props?.leadingIcon ? iconResolver(rest.props.leadingIcon) : null
        }
        leadingIconStyle={{
          width: 33,
          height: 33,
        }}
        trailingIcon={
          rest?.props?.trailingIcon
            ? iconResolver(rest.props.trailingIcon)
            : null
        }
        onTrailingIconPress={() => rest?.props?.onTrailingIconPress()}
        trailingIconStyle={{ width: 23, height: 23, right: 6 }}
        text1={text1}
        text1NumberOfLines={2}
        text2={null}
      />
    );
  },
};
