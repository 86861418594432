'use strict'

module.exports.default = {
	NODE_ENV: 'development',
	ENVIRONMENT: 'uat',
	MS_SOCO_PUBLIC_API_URL : 'https://soco-api.lilla.id',
	MS_SOCO_ADMIN_API_URL : 'https://soco-admin-api.sociolla.com',
	MS_BJ_PUBLIC_API_URL : 'https://bj-public-api.lilla.id',
	MS_BJ_ADMIN_API_URL : 'https://bj-admin-api.sociolla.com',
	MS_SOCIOLLA_API_URL : 'https://www.sociolla.com',
	MS_SOCKET_API_URL : 'https://websocket.sociolla.com',
	MS_CATALOG_API_URL : 'https://catalog-api.lilla.id',
	MS_CART_API_URL : 'https://carts-api.lilla.id',
	MS_SOCIOLLA_PUBLIC_URL : 'https://www.sociolla.com',
	MS_PAYMENT_API_URL : 'https://payments-api.lilla.id',
	MS_ORDER_API_URL : 'https://orders-api.lilla.id',
	MS_SHIPPING_API_URL : 'https://shipping-api.lilla.id',
	MS_SSO_BROKER_API_URL : 'https://sso-broker.lilla.id',
	MS_POS_API_URL : 'https://pos-api.sociolla.com',
	MS_SOCIOLLA_PUBLIC_API_URL : 'https://sociolla-api.lilla.id',
	MS_ANALYTICS_API_URL : 'https://analytics-api.lilla.id',
	MS_CHATBOT_API_URL : 'https://chatbot-api.sociolla.info',
	MS_ACCOUNTS_API_URL : 'https://accounts-api.lilla.id',
	MS_LULLA_API_URL : 'https://lulla-api.sociolla.com',
	SOCO_WEB_URL : 'https://www.soco.id',
	SOCO_ADMIN_URL : 'https://admin.soco.id',
	BJ_WEB_URL : 'https://journal.sociolla.com',
	BJ_ADMIN_URL : 'https://admin-bj.sociolla.com',
	REVIEW_WEB_URL : 'https://review.soco.id',
	STORE_WEB_URL : 'https://store-kota.sociolla.com',
	SOCIOLLA_WEB_URL : 'https://www.sociolla.com',
	LULLA_WEB_URL : 'https://lilla.id',
	BranchKey: 'key_live_mf1l06X7fHurf6JGSZ1piaeouzfcNM0O',
	SOCIOLLA_WEB_VN_URL : 'https://vn.sociolla.com',
	MS_SOCO_PUBLIC_API_LIST: ['https://soco-api.lilla.id'],
	MS_BJ_PUBLIC_API_LIST: ['https://bj-public-api.lilla.id'],
	MS_CATALOG_API_LIST: ['https://catalog-api.lilla.id', 'https://catalog-api1.lilla.id', 'https://catalog-api2.lilla.id', 'https://catalog-api3.lilla.id', 'https://catalog-api4.lilla.id', 'https://catalog-api5.lilla.id', 'https://catalog-api6.lilla.id', 'https://catalog-api7.lilla.id'],
	MS_CART_API_LIST: ['https://carts-api.lilla.id', 'https://carts-api1.lilla.id', 'https://carts-api2.lilla.id', 'https://carts-api3.lilla.id', 'https://carts-api4.lilla.id', 'https://carts-api5.lilla.id'],
	store_id: 5,
	store_lists: [
		{ id: 5, value: 'KOKAS' },
		{ id: 7, value: 'LMP' }
	],
	midtrans: {
		env: 'production',
		client_key: 'Mid-client-x-g8vSPxDst0hZig',
		url: 'https://api.midtrans.com'
	},
	consoleLogMode: false,
	OW_API_KEY: "59fed3396a52de044c2066984baafca6",
	GOOGLE_MAP_KEY: "AIzaSyAmrtZe_iHdzj0i7i1jjqnjrPtomG0B0is",
	RECAPTCHA_SITE_KEY: '6LdGhu0UAAAAAHRDh2bM97TEDB9UMBZSLjtsKe4F',
	FB_APP_ID: "767861140702004"
}
